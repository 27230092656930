body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f2f2f2;
}

.movie-card {
  background-color: #fff;
  border: 0;
  box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.24);
  padding: 1rem;
  cursor: pointer;
  position: relative;
  margin: 1rem auto;
}

.movie-director {
  padding: 4px 0;
  font-size: 0.8rem;
}

.saved-list {
  background-color: #fff;
  border: 0;
  box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.24);
  padding: 1rem;
  cursor: pointer;
  position: relative;
  margin: 1rem auto;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saved-list h3 {
  margin-right: 10px;
}

.saved-movie {
  margin: 0 10px;
}
.saved-active {
  color: blue;
  border: 1px solid grey;
  padding: 5px;
}
.save-wrapper {
  position: relative;
  width: 75%;
  margin: 1rem auto;
}
.save-button {
  position: absolute;
  top: 25px;
  right: 25px;
  border: 1px solid grey;
  padding: 5px 10px;
  background-color: lightseagreen;
}
.save-button:hover {
  background-color: green;
  color: white;
}

.home-button {
  padding: 5px 10px;
  background-color: lightskyblue;
}

.movie-warning {
  color: red;
  text-align: center;
}

.update-button {
  position: absolute;
  top: 80px;
  right: 25px;
  border: 1px solid grey;
  padding: 5px 10px;
  background-color: lightseagreen;
}

.delete-button {
  position: absolute;
  top: 135px;
  right: 25px;
  border: 1px solid grey;
  padding: 5px 10px;
  background-color: lightseagreen;
}